<template>
  <div class="marksheet">
    <div class="flex flex-between">
      <h1 class="fs-20 fw-600 truncate" style="max-width: 1030px">
        Test Analysis <span style="color: rgb(100, 44, 144)"> / Marksheet</span>
      </h1>
      <a class="back-btn waves-effect waves-light btn getclassbutton" @click="Goback()"
        ><span class="Tiny material-icons" style="position: relative; top: 7px; margin-right: 2px"
          >arrow_back</span
        >Go Back</a
      >
    </div>
    <div class="flex flex-between" style="margin: 24px 0 12px 0" v-if="marksheetDetail.length > 0">
      <p
        class="flex items-center skyblue-text-clr pointer"
        @click="
          showAllDetails = !showAllDetails;
          detailIndex = null;
        "
      >
        <span style="margin-right: 7px"> Detail </span>
        <i class="material-icons fs-15">{{ showAllDetails ? "expand_less" : "expand_more" }}</i>
      </p>
      <div>
        <button
          class="btn waves-effect waves-light fs-14 fw-600 download-btn"
          style="margin-right: 15px"
          @click="regenerateReport()"
        >
          Regenerate Report
        </button>
        <button
          class="btn waves-effect waves-light fs-14 fw-600 download-btn"
          @click="downloadCsv()"
        >
          Download CSV file
        </button>
      </div>
    </div>
    <div class="table-main scroll-horizontal w-100" v-if="marksheetDetail.length > 0">
      <div
        class="table-header"
        :style="`grid-template-columns: repeat(${marksheetDetail[0].sections.length + 2}, 142px)`"
      >
        <p class="fs-14 fw-500">Student</p>
        <p class="fs-14 fw-500">Overall</p>
        <div v-for="(section, index) in marksheetDetail[0].sections" :key="index">
          <p class="fs-14 fw-500">{{ section.sectionName }}</p>
          <p class="fs-13 fw-700 skyblue-light flex items-center">
            <img
              :src="require(`../assets/target (1) 2.svg`)"
              style="margin-right: 5px"
              :title="`Attempted: ${section.Attempted}`"
            />{{ section.Attempted }}
          </p>
        </div>
      </div>
      <div
        class="student-detail white pointer"
        :style="`grid-template-columns: repeat(${marksheetDetail[0].sections.length + 2}, 142px)`"
        @click="showOverall(index1)"
        v-for="(item, index1) in marksheetDetail"
        :key="index1"
      >
        <p class="fs-14 fw-500">{{ item.studentName }}</p>
        <p class="fs-14 fw-500 flex">
          <img
            :src="require(`../assets/marks13.svg`)"
            style="margin-right: 5px; height: max-content"
            :title="`Total Marks: ${item.totalMarks}`"
          /><span style="margin-right: 13px">{{ item.totalMarks }}</span>
          <img
            :src="require(`../assets/rank13.svg`)"
            style="margin-right: 5px; height: max-content"
            :title="`Rank: ${item.finalRank}`"
          />
          <span>{{ item.finalRank }}</span>
        </p>
        <div v-for="(studentDetail, index2) in item.sections" :key="index2">
          <div class="detail-header">
            <p class="fs-14 fw-500 flex">
              <img
                :src="require(`../assets/marks13.svg`)"
                style="margin-right: 5px"
                :title="`Marks: ${studentDetail.Marks}`"
              /><span style="margin-right: 13px">{{ studentDetail.Marks }}</span>
              <img
                :src="require(`../assets/rank13.svg`)"
                style="margin-right: 5px"
                :title="`Rank: ${studentDetail.Rank}`"
              />
              <span>{{ studentDetail.Rank }}</span>
            </p>
          </div>
          <div
            class="detail-overall"
            v-if="(showOverallDetails && detailIndex === index1) || showAllDetails"
          >
            <div class="text-center" :title="`Total: ${studentDetail.Total}`">
              <img :src="require(`../assets/total12.svg`)" alt="" />
              <p class="fs-14 fw-500">{{ studentDetail.Total }}</p>
            </div>
            <div class="text-center" :title="`Correct: ${studentDetail.Correct}`">
              <img :src="require(`../assets/correct13.svg`)" alt="" />
              <p class="fs-14 fw-500">{{ studentDetail.Correct }}</p>
            </div>
            <div class="text-center" :title="`Wrong: ${studentDetail.Wrong}`">
              <img :src="require(`../assets/wrong13.svg`)" alt="" />
              <p class="fs-14 fw-500">{{ studentDetail.Wrong }}</p>
            </div>
            <div class="text-center" :title="`Skipped: ${studentDetail.Skipped}`">
              <img :src="require(`../assets/skip13.svg`)" alt="" />
              <p class="fs-14 fw-500">{{ studentDetail.Skipped }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center" style="margin-bottom: 20px">
      <img
        :src="require(`../assets/no-result-found.png`)"
        alt=""
        style="width: 200px; opacity: 0.7"
      />
      <p class="fw-400 fs-15" style="margin-top: -20px; color: #8c8585">Marksheet not available.</p>
    </div>
  </div>
</template>

<script>
import exportFromJSON from "export-from-json";
import { useToast } from "vue-toastification";
import Mobileapi from "../Mobileapi";

const toast = useToast();

export default {
  data() {
    return {
      showOverallDetails: false,
      marksheetDetail: [],
      detailIndex: null,
      showAllDetails: false,
      instituteTestId: null,
    };
  },
  beforeMount() {
    document.body.style.backgroundColor = "#F6F8FB";
  },
  created() {
    this.instituteTestId = Number(this.$route.params.instituteTestId);
    this.$store.dispatch("showLoader", true);
    Mobileapi.getInstituteTestReport(this.instituteTestId, (response) => {
      if (response.responseCode === 200) {
        if (response.data.length > 0) {
          this.getMarksheetDetail(response);
        } else {
          this.marksheetDetail = [];
          toast.error("Marksheet not available", {
            timeout: 2500,
          });
        }
      }
      if (response.responseCode === 500) {
        this.marksheetDetail = [];
        toast.error(`${response?.data.info}`, {
          timeout: 2500,
        });
      }
      this.$store.dispatch("showLoader", false);
    });
  },
  methods: {
    Goback() {
      this.$router.push({
        name: "ManageInstituteTest",
        params: { isNavigate: 1 },
      });
    },
    showOverall(index) {
      this.showAllDetails = false;
      if (this.detailIndex === index) {
        this.detailIndex = null;
        this.showOverallDetails = false;
      } else {
        this.detailIndex = index;
        this.showOverallDetails = true;
      }
    },
    regenerateReport() {
      this.$store.dispatch("showLoader", true);
      Mobileapi.reGenerateTestReport(
        {
          testId: this.instituteTestId,
        },
        (response) => {
          if (response.data.responseCode === 200) {
            if (response.data.data.length > 0) {
              this.getMarksheetDetail(response.data);
            } else {
              this.marksheetDetail = [];
            }
          } else {
            this.marksheetDetail = [];
            toast.error(`${response.data.message}`, {
              timeout: 2500,
            });
          }
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    getMarksheetDetail(response) {
      this.marksheetDetail = response.data.map((student) => {
        const sections = Object.entries(student).filter((entry) => typeof entry[1] === "object");
        return {
          finalRank: student.finalRank,
          studentName: student.studentName,
          totalMarks: student.totalMarks,
          user: student.user,
          sections: sections.map(([sectionId, sectionDetails]) => ({
            ...sectionDetails,
            sectionId,
          })),
        };
      });
    },
    downloadCsv() {
      Mobileapi.getInstituteTestPartWiseUserExtensiveReport(
        { InstituteTestId: this.instituteTestId },
        (response) => {
          if (response.responseCode === 200) {
            if (response.data.length > 0) {
              const data = response.data;
              const fileName = "institute-test-marksheet";
              const exportType = exportFromJSON.types.csv;
              if (data) {
                exportFromJSON({
                  data,
                  fileName,
                  exportType,
                });
              }
            } else {
              toast.error("Marksheet not available", {
                timeout: 2500,
              });
            }
          } else {
            toast.error(`${response?.data.info}`, {
              timeout: 2500,
            });
          }
        },
      );
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

.marksheet {
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box !important;
  margin-top: 28px;
}
.skyblue-text-clr {
  color: #3751ff;
}
.skyblue-light {
  color: #5c8eff;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.back-btn {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
}
.btn:hover {
  box-shadow: none !important;
}
.download-btn {
  background-color: #16a085;
  height: 35px;
  width: 170px;
  text-transform: capitalize !important;
}
.table-main {
  display: grid;
  grid-gap: 12px;
}
.table-header,
.student-detail {
  padding: 12px 24px;
  background: #f3f3f3;
  border-radius: 4px 4px 0px 0px;
  display: grid;
  grid-gap: 46px;
}
.detail-overall {
  margin-top: 14px;
  display: grid;
  grid-template-columns: repeat(4, 20px);
  grid-gap: 7px;
}
</style>
